<template>
  <v-card
    flat
    class="pa-3 mt-2"
  >
    <v-card-text>
      <v-form
        ref="form"
        class="multi-col-validation mt-6"
      >
        <v-row>
          <v-col
            cols="12"
            md="4"
          >
            <v-row>
              <v-col
                cols="12"
              >
                <v-autocomplete
                  v-model="dataComputed.client_id"
                  dense
                  outlined
                  :label="t('projects.client')"
                  :items="clientsOptions"
                  item-text="name"
                  item-value="id"
                  :append-icon="null"
                  readonly
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col
                cols="12"
              >
                <v-autocomplete
                  v-model="dataComputed.project_id"
                  dense
                  outlined
                  :label="t('projects.project')"
                  :items="projectsOptions"
                  item-text="name"
                  item-value="id"
                  :append-icon="null"
                  readonly
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col
                cols="12"
              >
                <v-autocomplete
                  v-model="dataComputed.vehicle_id"
                  dense
                  outlined
                  :label="t('projects.vehicle')"
                  :items="vehiclesOptions"
                  :item-text="(item) => `${item.id_number} - ${item.model} ${item.color}`"
                  item-value="id"
                  :append-icon="dataComputed.status !== 'pending' ? null : icons.mdiMenuDown"
                  :readonly="dataComputed.status !== 'pending'"
                  :rules="[required]"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col
                cols="12"
              >
                <v-autocomplete
                  v-model="dataComputed.driver_id"
                  dense
                  outlined
                  :label="t('projects.driver')"
                  :items="driversOptions"
                  item-text="fullname"
                  item-value="id"
                  :append-icon="dataComputed.status !== 'pending' ? null : icons.mdiMenuDown"
                  :readonly="dataComputed.status !== 'pending'"
                  :rules="[required]"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col
                cols="12"
              >
                <v-autocomplete
                  v-model="dataComputed.mix_type_id"
                  dense
                  outlined
                  :label="t('projects.mix_type')"
                  :items="mixsOptions"
                  item-text="name"
                  item-value="id"
                  :append-icon="null"
                  readonly
                />
              </v-col>
            </v-row>
          </v-col>

          <v-divider
            vertical
          />

          <v-col
            cols="12"
            md="8"
          >
            <v-row>
              <v-col
                cols="12"
                md="3"
              >
                <v-btn
                  class="text-h6"
                  color="secondary"
                  block
                  :disabled="symStatus === false || dataComputed.status !== 'processing'"
                  @click="onRead"
                >
                  Leer
                </v-btn>
              </v-col>

              <v-col
                cols="12"
                md="3"
              >
                <v-text-field
                  v-model.number="qty"
                  :label="t('products.per_kg')"
                  class="text-h6"
                  dense
                  outlined
                  readonly
                  :disabled="symStatus === false || dataComputed.status !== 'processing'"
                  @keypress="onlyNumberDot"
                  @keypress.enter="onRead"
                />
              </v-col>

              <v-col
                cols="12"
                md="6"
              >
                <v-btn
                  class="text-h6"
                  color="secondary"
                  block
                  :disabled="symStatus !== true || dataComputed.status !== 'processing'"
                  @click="onAddWeight"
                >
                  {{ $t('tooltip.add') }}
                </v-btn>
              </v-col>
            </v-row>

            <v-row v-if="symStatus === false">
              <v-col
                cols="12"
                md="3"
              />

              <v-col
                cols="12"
                md="3"
              >
                <v-text-field
                  v-model.number="manualQty"
                  :label="t('products.per_kg')"
                  class="text-h6"
                  dense
                  outlined
                  @keypress="onlyNumberDot"
                  @keypress.enter="onRead"
                />
              </v-col>

              <v-col
                cols="12"
                md="6"
              >
                <v-btn
                  class="text-h6"
                  color="secondary"
                  block
                  :disabled="!manualQty || Number.isNaN(manualQty) || manualQty <= 0"
                  @click="onAddWeight"
                >
                  {{ $t('tooltip.add_manual_value') }}
                </v-btn>
              </v-col>

              <v-col
                cols="12"
                md="3"
              />

              <v-col
                cols="12"
                md="9"
              >
                <v-textarea
                  v-model.number="observation"
                  :label="t('products.observation')"
                  rows="2"
                  class="text-h6"
                  dense
                  outlined
                ></v-textarea>
              </v-col>
            </v-row>

            <v-row>
              <v-col
                cols="12"
              >
                <v-data-table
                  :headers="tableColumns"
                  :items="shippingHeaps"
                  item-key="id"
                  :items-per-page="-1"
                  :loading-text="t('table.loading_text')"
                  :no-data-text="t('table.no_data')"
                  hide-default-footer
                  :header-props="{sortByText: t('table.sort_by')}"
                >
                  <template #[`item.actions`]="{item}">
                    <v-tooltip
                      v-if="item.register_type === 'manual' && dataComputed.status === 'processing'"
                      bottom
                    >
                      <template #activator="{ on: tooltip, attrs }">
                        <v-icon
                          size="20"
                          class="me-2"
                          color="error"
                          v-bind="attrs"
                          v-on="{ ...tooltip }"
                          @click="onDeleteHeap(item.id)"
                        >
                          {{ icons.mdiDeleteOutline }}
                        </v-icon>
                      </template>
                      <span>{{ t('tooltip.delete') }}</span>
                    </v-tooltip>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>

            <v-row justify="end">
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model.number="totalLabel"
                  label="Total"
                  class="text-h6"
                  dense
                  outlined
                  readonly
                  :disabled="dataComputed.status !== 'processing'"
                />
              </v-col>
            </v-row>
          </v-col>

          <v-col
            cols="12"
          >
            <v-row>
              <v-col
                cols="12"
                md="6"
              >
                <v-btn
                  class="text-h6"
                  color="success"
                  block
                  :disabled="dataComputed.status !== 'pending'"
                  @click="onStartDispatch"
                >
                  {{ $t('projects.start_shipping') }}
                </v-btn>
              </v-col>

              <v-col
                cols="12"
                md="6"
              >
                <v-btn
                  class="text-h6"
                  color="error"
                  block
                  :disabled="dataComputed.status !== 'processing'"
                  @click="onFinishDispatch"
                >
                  {{ $t('projects.finish_shipping') }}
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { mdiMenuDown, mdiCalendar, mdiDeleteOutline } from '@mdi/js'
import { ref, computed, onMounted } from '@vue/composition-api'
import { required, emailValidator, integerValidator } from '@core/utils/validation'
import { useUtils } from '@core/libs/i18n'
import { onlyNumberDot } from '@/@core/utils/functionsHelpers'
import { truncate3decimal } from '@core/utils'
import {
  getClientsActive, getProductsActiveClient, getMixTypesActive,
  getVehiclesActive, getDriversActive, symHeap, createHeap,
  deleteHeap, getShippingHeaps, editShipping,
} from '@api'
import moment from 'moment'

export default {
  props: {
    option: {
      type: Number,
      default: 0,
    },
    tab: {
      type: Number,
      default: 0,
    },
    dataParams: {
      type: Object,
      default: () => {},
    },
  },
  setup(props, { emit }) {
    const { t } = useUtils()
    const form = ref(null)
    const dataComputed = computed(() => props.dataParams)

    const modal = ref(false)
    const dataAcount = ref({})
    const initialData = {
      name: null,
      note: null,
      status: null,
    }
    const qty = ref(0)
    const manualQty = ref(0)
    const observation = ref(null)
    const symStatus = ref(null)
    const symData = ref([])
    const clientsOptions = ref([])
    const projectsOptions = ref([])
    const mixsOptions = ref([])
    const vehiclesOptions = ref([])
    const driversOptions = ref([])
    const shippingHeaps = ref([])
    const listTable = ref([])
    const total = computed(() => shippingHeaps.value.reduce((acc, item) => acc + item.heap_weight, 0))
    const totalLabel = computed(() => `${truncate3decimal(total.value)} ${t('projects.tons')}`)

    const tableColumns = computed(() => [
      {
        text: t('projects.date').toUpperCase(), value: 'register_date', show: true, sortable: false,
      },
      {
        text: t('projects.type').toUpperCase(), value: 'register_type', show: true, sortable: false,
      },
      {
        text: `${t('projects.weight').toUpperCase()} (${t('projects.tons').toUpperCase()})`, value: 'heap_weight', show: true, sortable: false,
      },
      {
        text: t('tooltip.actions').toUpperCase(), value: 'actions', align: 'center', show: true, sortable: false,
      },
    ])

    const statusOptions = computed(() => [
      { text: t('status.active'), value: 'A' },
      { text: t('status.inactive'), value: 'I' },
    ])

    const resolveStatusVariant = () => {
      if (symStatus.value) return 'success'
      if (!symStatus.value) return 'error'

      return 'primary'
    }

    const fetchProjectsByClient = async client => {
      if (client) {
        const response = await getProductsActiveClient(client)
        if (response.ok) {
          projectsOptions.value = response.data
        } else {
          projectsOptions.value = []
        }
      }
    }

    const fetchShippingHeaps = async () => {
      const response6 = await getShippingHeaps(dataComputed.value.id)
      if (response6.ok) {
        shippingHeaps.value = response6.data.map(item => ({ ...item, register_date: moment(item.register_date).format('DD/MM/YYYY HH:mm:ss') }))
      } else {
        shippingHeaps.value = []
      }
    }

    const onRead = async () => {
      const response = await symHeap()
      if (response.ok) {
        symStatus.value = true
        symData.value = response.data
        qty.value = symData.value.weight
      } else {
        symStatus.value = false
        symData.value = null
        qty.value = 0
      }
    }

    const onAddWeight = async () => {
      const weight = symStatus.value ? qty.value / 1000 : manualQty.value / 1000
      const response = await createHeap({
        shipping_id: dataComputed.value.id,
        heap_weight: weight,
        register_type: symStatus.value ? 'automatic' : 'manual',
        note: symStatus.value ? null : observation.value,
      })
      if (response.ok) {
        await fetchShippingHeaps()

        // shippingHeaps.value.unshift({
        //   id: response.data.id || null,
        //   heap_weight: symStatus.value ? qty.value : manualQty.value,
        //   register_type: symStatus.value ? 'automatic' : 'manual',
        //   register_date: moment().format('DD/MM/YYYY HH:mm:ss'),
        // })

        qty.value = 0
        manualQty.value = 0
        observation.value = null
        symStatus.value = null
        symData.value = null
      }
    }

    const onDeleteHeap = async id => {
      const response = await deleteHeap(id)
      if (response.ok) {
        shippingHeaps.value = shippingHeaps.value.filter(e => e.id !== id)
      }
    }

    const onStartDispatch = async () => {
      const response = await editShipping({
        status: 'processing',
        vehicle_id: dataComputed.value.vehicle_id,
        driver_id: dataComputed.value.driver_id,
      }, dataComputed.value.id)

      if (response.ok) {
        emit('refresh')
      }
    }

    const onFinishDispatch = async () => {
      const response = await editShipping({
        status: 'completed',
      }, dataComputed.value.id)

      if (response.ok) {
        emit('refresh')
      }
    }

    const onSaveAction = () => {
      if (form.value.validate()) {
        emit('save', dataComputed.value)
      }
    }

    const onCancelButton = () => {
      form.value.reset()
      dataAcount.value = Object.assign(initialData, {
        name: null,
        note: null,
        status: null,
      })
    }

    const changeTab = tab => {
      if (form.value.validate()) {
        emit('changeTab', tab, true, dataComputed.value)
      }
    }

    onMounted(async () => {
      const response = await getClientsActive()
      if (response.ok) {
        clientsOptions.value = response.data
      } else {
        clientsOptions.value = []
      }

      const response3 = await getMixTypesActive()
      if (response3.ok) {
        mixsOptions.value = response3.data
      } else {
        mixsOptions.value = []
      }

      const response4 = await getVehiclesActive()
      if (response4.ok) {
        vehiclesOptions.value = response4.data
      } else {
        vehiclesOptions.value = []
      }

      const response5 = await getDriversActive()
      if (response5.ok) {
        driversOptions.value = response5.data
      } else {
        driversOptions.value = []
      }

      await fetchProjectsByClient(dataComputed.value.client_id)
      await fetchShippingHeaps()
    })

    return {
      // data
      form,
      modal,
      dataAcount,
      initialData,
      clientsOptions,
      projectsOptions,
      mixsOptions,
      vehiclesOptions,
      driversOptions,
      shippingHeaps,
      listTable,
      observation,
      qty,
      manualQty,
      symData,
      symStatus,

      // computed
      dataComputed,
      tableColumns,
      statusOptions,
      total,
      totalLabel,

      // methods
      resolveStatusVariant,
      onCancelButton,
      changeTab,
      onSaveAction,
      fetchProjectsByClient,
      onRead,
      onAddWeight,
      onDeleteHeap,
      onStartDispatch,
      onFinishDispatch,

      // validations
      required,
      emailValidator,
      integerValidator,
      onlyNumberDot,

      // i18n
      t,

      icons: {
        mdiMenuDown,
        mdiCalendar,
        mdiDeleteOutline,
      },
    }
  },
}
</script>
